<template>
  <div style="padding-top: 10px">
    <a-spin :spinning="spinning">
      <a-form :label-col="labelCol" :wrapper-col="wrapperCol" :form="form" class="p0-32-10-32 form-content">
        <a-form-item label="名称">
          <a-input style="width: 96%" v-decorator="[
              'name',
              { rules: [{ required: true, message: '调度名称不能为空!' }] },
            ]" placeholder="请输入名称" />
        </a-form-item>
        <a-form-item label="目标">
          <a-select style="width: 96%" placeholder="请选择目标" v-decorator="[
              'target_id',
              { rules: [{ required: true, message: '目标不能为空!' }] },
            ]">
            <a-select-option v-for="(item, index) in targetList" :key="index" :value="item.value">{{ item.label }}</a-select-option>
          </a-select>
          </a-form-item>
        <a-form-item label="是否启用">
          <a-switch v-decorator="['enabled', { valuePropName: 'checked' }]" />
        </a-form-item>
        <a-form-item label="类型">
          <a-radio-group v-decorator="[
              'belong_type',
              { rules: [{ required: true, message: '调度类型不能为空!' }] },
            ]">
            <a-radio value="task">任务</a-radio>
            <a-radio value="embed_script">脚本</a-radio>
            <a-radio value="embed_cmd">命令</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item v-if="belongType === 'task'" label="任务">
          <a-select style="width: 96%" placeholder="请选择任务" v-decorator="[
              'belong_id',
              { rules: [{ required: true, message: '调度任务不能为空!' }] },
            ]">
            <a-select-option v-for="(item, index) in region" :key="index" :value="item.task_id">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="belongType === 'embed_script'" label="脚本">
          <a-auto-complete v-decorator="[
              'source',
              { rules: [{ required: true, message: '脚本不能为空!' }] },
            ]" :data-source="scriptList" placeholder="输入或选择脚本" />
        </a-form-item>
        <a-form-item v-if="belongType === 'embed_cmd'" label="命令">
          <a-input type="textarea" style="width: 96%" v-decorator="[
              'code',
              { rules: [{ required: true, message: '命令内容不能为空!' }] },
            ]" placeholder="请输入命令内容" />
        </a-form-item>
        <a-form-item label="描述">
          <a-input style="width: 96%" v-decorator="[
              'description',
              { rules: [{ required: false, message: '调度描述不能为空!' }] },
            ]" placeholder="请输入描述" />
        </a-form-item>
      </a-form>
      <Rrule ref="RruleDispatch" :data="rruleObj" @saveRrule="saveDispatch" />
      <div class="ant-modal-confirm-btns-new">
        <a-button style="margin-right: 10px" type="primary" @click.stop="handleSubmit" :loading="loading">确认</a-button>
        <a-button @click.stop="formCancel">取消</a-button>
      </div>
    </a-spin>
  </div>
</template>
<script>
import moment from "moment";

import Rrule from "@/components/rrule/rrule";
import target from "@/api/httpApi/target";
export default {
  components: { Rrule },
  props: {
    detail: {
      type: Object,
      default: function () {
        return {};
      },
    },
    callBack: Function,
    clearSelectIndex: Function
  },
  data() {
    const self = this
    return {
      flag: {
        ok: true,
        no: false,
      },
      targetList: [],
      scriptList: [],
      region: [],
      ui_data: {},
      belongType: 'task',
      rruleObj: {
        scheduleCycle: 3,
        startValue: moment(new Date()).format("YYYY-MM-DD HH:mm"),
        endValue: moment(new Date()).format("YYYY-MM-DD HH:mm"),
        tzid: "Asia/Shanghai",
      },
      editFlag: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
      initFormFiledFlag: true,
      form: this.$form.createForm(this, {
        onValuesChange: function (props, fileds) {
          if (self.initFormFiledFlag) {
            self.initFormFiledFlag = false;
            return false;
          }
          for (var key in fileds) {
            if(key.includes('belong_type')) {
                self.belongType = fileds[key]
            }
          }
        },
      }),
      loading: false,
      spinning: false,
    };
  },
  watch: {},
  methods: {
    getHostGroups() {
      const params = {
        page_size: 10000,
        page: 1,
      };
      this.$axiosGet(global.API.getTargets, params).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          this.targetList = res.data.results.map(item => {
            return {
              label: item.name,
              value: item.id
            }
          })
        }
      });
    },
    checkName(rule, value, callback) {
      var reg = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g;
      if (reg.test(value)) {
        callback(new Error("名称中不能包含中文"));
      }
      if (/\s/g.test(value)) {
        callback(new Error("名称中不能包含空格"));
      }
      callback();
    },
    formCancel() {
      this.clearSelectIndex()
      this.$destroyAll();
    },
    onChange() {
      console.log(1);
    },

    handleSubmit(e) {
      console.log(this.detail.rrule, "hjlkm;fcvglkmhb");
      const _this = this;
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          this.$refs.RruleDispatch.save(values);
        }
      });
    },
    saveDispatch(values, rruleObj) {
      const _this = this;
      if (!rruleObj.toString) return false;
      let params = {
        name: values.name,
        description: values.description,
        rrule: rruleObj.toString,
        belong_type: values.belong_type,
        ui_data: JSON.stringify(rruleObj),
        enabled: !!values.enabled,
        target_id: values.target_id
      };
      if (values.belong_type === 'task') {
        params.belong_id = values.belong_id
      }
      if (values.belong_type === 'embed_script') {
        params.source = values.source
      }
      if (values.belong_type === 'embed_cmd') {
        params.code = values.code
        params.lang = 'bash'
      }
      if (this.detail.title === "1") {
        this.loading = true;
        this.$axiosJsonPost(global.API.getDispatchList, params)
          .then((res) => {
            this.loading = false;
            if ([200, 201, 204].includes(res.status)) {
              this.$message.success("添加成功", 2);
              this.$destroyAll();
              _this.callBack();
            }
          })
          .catch((err) => {});
        return;
      }
      if (this.detail.title === "2") {
        const id = this.detail.id;
        console.log(typeof id);
        this.$axiosPatch(global.API.getDispatchList + `/${id}`, params).then(
          (res) => {
            console.log(res);
            // this.loading = false;
            if ([200, 201, 204].includes(res.status)) {
              this.$message.success("编辑成功", 2);
              this.$destroyAll();
              _this.callBack();
            }
          }
        );
      }
    },
    getTaskList() {
      this.spinning = true;
      const params = {
        page_size: 999,
        page: 1,
        excludes: 'operations,detail'
      };
      this.$axiosGet(global.API.curdTasks, params).then((res) => {
        this.spinning = false;
        if ([200, 201, 204].includes(res.status)) {
          this.region = res.data.results;
          setTimeout(() => {
            this.echoUSer();
          }, 1000)
        }
      });
    },
    echoUSer() {
      if (JSON.stringify(this.detail.row) !== "{}") {
        this.editFlag = true;
        this.form.getFieldsValue([
          "name",
          "description",
          "belong_type",
          "belong_id",
          "enabled",
          'code',
          'source',
          'target_id'
        ]);
        this.form.setFieldsValue({
          name: this.detail.row.name,
          description: this.detail.row.description,
          belong_type: this.detail.row.belong_type,
          enabled: this.detail.row.enabled,
          belong_id: this.detail.row.belong_id,
          target_id: this.detail.row.target_id
        });
        if (this.detail.row.belong_type === 'task') {
          this.belongType = 'task'
          this.form.getFieldsValue(['belong_id'])
          this.form.setFieldsValue({
            belong_id: this.detail.row.belong_id,
          });
        }
        if (this.detail.row.belong_type === 'embed_script') {
          this.belongType = 'embed_script'
          this.form.getFieldsValue(['source'])
          this.form.setFieldsValue({
            source: this.detail.row.source,
          });
        }
        if (this.detail.row.belong_type === 'embed_cmd') {
          this.belongType = 'embed_cmd'
          this.form.getFieldsValue(['code'])
          this.form.setFieldsValue({
            code: this.detail.row.code,
          });
        }
        this.rruleObj = JSON.parse(this.detail.row.ui_data);
      } else {
        this.form.getFieldsValue([
          "name",
          "description",
          "belong_type",
          "belong_id",
          "enabled",
          "target_id"
        ]);
        this.form.setFieldsValue({
          name: "",
          description: "",
          belong_type: 'task',
          enabled: false,
          belong_id: undefined,
          target_id: undefined
        });
      }
    },
  },
  mounted() {
    this.getTaskList();
    this.getHostGroups()
  },
  created() {},
};
</script>
<style lang="less" scoped></style>
