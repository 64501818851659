<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-08-30 22:56:19
 * @LastEditTime: 2022-09-01 10:19:48
 * @FilePath: \awx-ui\src\pages\dispatch\commponents\detail.vue
-->
<template>
  <a-spin :spinning="loading" class="p20">
		<div class="detail-container">
			<div><span class="label">名称：</span> <span>{{detailObj.name}}</span></div>
			<div><span class="label">首次运行：</span><span>{{detailObj.dtstart}}</span></div>
			<div><span class="label">最后运行：</span><span>{{detailObj.dtend}}</span></div>
			<div><span class="label">本地时区：</span><span>CN/shanghai</span></div>
			<div><span class="label">重复频率：</span><span></span></div>
			<div><span class="label">已创建：</span><span>{{detailObj.create_time}}</span></div>
      <div><span class="label">最后修改：</span><span>{{detailObj.update_time}}</span></div>
			<div><span class="label">描述：</span> <span>{{detailObj.description}}</span></div>
			<div><span class="label">发生次数（限制为前10）</span></div>
		</div>
     <a-radio-group v-model="tabPosition" style="margin:0 10px 10px">
      <a-radio-button value="local">
        本地
      </a-radio-button>
      <a-radio-button value="utc">
        UTC
      </a-radio-button>
    </a-radio-group>
    <div class="run-list">
      <div class="run-item" v-for="(item, index) in runList" :key="index">{{item}}</div>
    </div>
  </a-spin>
</template>
<script>
import moment from 'moment'
export default {
  props: {
    detail: Object,
  },
  data() {
    return {
      loading: false,
      detailObj: {},
      tabPosition: 'local',
      runList: []
		  };
  },
  watch: {
    tabPosition: {
      handler(val) {
        let list = val === 'local' ? this.detailObj.preview.local : this.detailObj.preview.utc
        this.runList = (list || []).map(item => moment(item).format('YYYY-MM-DD HH:mm:ss'))
      }
    }
  },
  methods: {
    getDetail() {
      this.loading = true;
      this.$axiosGet(
        global.API.getDispatchList + "/" + this.detail.id,
        {}
      ).then((res) => {
        this.loading = false;
        if ([200, 201, 204].includes(res.status)) {
          this.detailObj = {
            name: res.data.detail.name,
            description: res.data.basic.description,
            create_time: moment(res.data.basic.create_time).format('YYYY-MM-DD HH:mm:ss'),
            update_time: moment(res.data.basic.update_time).format('YYYY-MM-DD HH:mm:ss'),
            dtend: moment(res.data.detail.dtend).format('YYYY-MM-DD HH:mm:ss'),
            dtstart: moment(res.data.detail.dtstart).format('YYYY-MM-DD HH:mm:ss'),
            next_run: moment(res.data.basic.next_run).format('YYYY-MM-DD HH:mm:ss'),
            timezone: res.data.detail.timezone,
            preview: res.data.preview
            // preview: {
            //   local: ['0001-01-01T00:00:00Z', '0001-01-01T00:00:00Z', '0001-01-01T00:00:00Z', '0001-01-01T00:00:00Z', '0001-01-01T00:00:00Z'],
            //   utc: ['0001-01-01T00:00:00Z', '0001-01-01T00:00:00Z', '0001-01-01T00:00:00Z', '0001-01-01T00:00:00Z', '0001-01-01T00:00:00Z']
            // }
          }
          this.runList = (this.detailObj.preview.local || []).map(item => moment(item).format('YYYY-MM-DD HH:mm:ss'))
        }
      });
    },
  },
  mounted() {
    this.getDetail();
  },
};
</script>
<style lang="less" scoped>
.p20 {
  padding:16px 32px;
}
.detail-container {
	display: flex;
	flex-wrap: wrap;
  div {
    width: 50%;
    color: #333;
    display: flex;
    padding: 5px 0;
    span {
      color: #666;
      display: block;
      padding-left: 10px;
    }
    .label {
      min-width: 90px;
      color: #333;
    }
  }
}
.run-list {
  margin: 0 10px;
  .run-item {
    margin: 0 0 5px 0;
  }
}
</style>