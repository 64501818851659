<template>
  <div class="card-container">
    <a-form-model ref="ruleForm" class="p0-32-10-32" :model="data" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item ref="scheduleCycle" label="调度周期" prop="scheduleCycle">
        <a-select v-model="data.scheduleCycle" style="width: 96%" placeholder="请选择" @change="changeDispatchType">
          <a-select-option v-for="ls in dispatchList" :key="ls.val" :value="ls.val">{{ls.title}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-row type="flex" align="middle">
        <a-col :span="12">
          <a-form-model-item ref="startValue" label="开始时间" :label-col="labelColItem" :wrapper-col="wrapperColItem" prop="startValue">
            <!-- <a-date-picker v-model="startValue" show-time format="YYYY-MM-DD HH:MM" placeholder="开始时间" /> -->
            <a-date-picker
              placeholder="开始时间" 
              v-model="data.startValue"
              format="YYYY-MM-DD HH:mm"
              :show-time="{ defaultValue: moment('00:00', 'HH:mm') }"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="1"></a-col>
        <a-col :span="10" class="mgl8">
          <a-form-model-item v-show="isValue == 2" ref="endValue" :label-col="labelColItem" :wrapper-col="wrapperColItem" label="结束时间" prop="endValue">
            <a-date-picker v-model="data.endValue" :disabled-date="disabledEndDate" :show-time="{ defaultValue: moment('00:00', 'HH:mm') }" placeholder="结束时间" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-form-model-item ref="isValue" label v-bind="formItemLayoutWithOutLabel" prop="isValue">
        <a-radio-group v-model="isValue">
          <a-radio value="0">永不</a-radio>
          <a-radio value="1">发生次数后</a-radio>
          <a-radio value="2">于日期</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <!-- 周和月-->
      <a-row v-if="[2, 3].includes(data.scheduleCycle)" type="flex" align="middle">
        <a-col :span="24">
          <a-form-model-item v-if="[2].includes(data.scheduleCycle)" ref="weeks" label="选择时间" prop="weeks">
            <a-select v-model="data.weeks" mode="multiple" style="width: 96%" placeholder="请选择">
              <a-select-option v-for="ls in loadTimeList(data.scheduleCycle)" :key="ls.val" :value="ls.val">{{ls.title}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="[3].includes(data.scheduleCycle)" ref="days" label="选择时间" prop="days">
            <a-select v-model="data.days" style="width: 96%" placeholder="请选择">
              <a-select-option v-for="ls in loadTimeList(data.scheduleCycle)" :key="ls.val" :value="ls.val">{{ls.title}}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row type="flex" align="middle">
        <a-col :span="24">
          <a-form-model-item ref="minutesInterval" label="间隔时间" prop="minutesInterval">
            <a-input-number v-model="data.minutesInterval" :min="0" style="width: 96%" placeholder="请输入" />
            <span class="mgl10">{{dispatchList.filter(item => item.val === data.scheduleCycle)[0].title}}</span>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-form-model-item v-if="isValue==1" ref="count" label="发生次数" prop="count">
        <a-input-number v-model="data.count" :min="0" style="width: 96%" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item ref="tzid" label="时区" prop="tzid">
        <a-select v-model="data.tzid" style="width: 96%" placeholder="请输入">
          <a-select-option value="shanghai">上海</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import moment from "moment";
const scheduleCycleMap = new Map([
  [
    "month",
    {
      value: 3,
    },
  ],
  [
    "week",
    {
      value: 2,
    },
  ],
  [
    "day",
    {
      value: 1,
    },
  ],
  [
    "hour",
    {
      value: 4,
    },
  ],
  [
    "minute",
    {
      value: 5,
    },
  ],
]);
const ecHoscheduleCycleMap = new Map([
  [
    3,
    {
      value: "month",
    },
  ],
  [
    2,
    {
      value: "week",
    },
  ],
  [
    1,
    {
      value: "day",
    },
  ],
  [
    4,
    {
      value: "hour",
    },
  ],
  [
    5,
    {
      value: "minute",
    },
  ],
]);

export default {
  name: "VueCron",
  props: {
    data: Object,
  },
  data() {
    return {
      startValue:'',
      endValue:'',
      scheduleCycleMap,
      ecHoscheduleCycleMap,
      visible: false,
      confirmLoading: false,
      size: "large",
      dispatchType: 5,
      timeList: [],
      dispatchList: [
        { title: "年", val: 0, value: "YEARLY" },
        { title: "月", val: 3, value: "MONTHLY" },
        { title: "周", val: 2, value: "WEEKLY" },
        { title: "天", val: 1, value: "DAILY" },
        { title: "时", val: 4, value: "HOURLY" },
        { title: "分", val: 5, value: "MINUTELY" },
      ],
      // selectTime: undefined,
      weekDays: ["天", "一", "二", "三", "四", "五", "六"].map(
        (val) => "星期" + val
      ),
      scheduleParams: {
        scheduleCycle: 5,
        weeks: [],
        days: [],
        // 调度周期 1天 2周 3月 4小时 5分钟 6自定义
        // private int scheduleCycle;
        // // 每周那些天 周一、周二等等
        // private String[] weeks;
        // // 每月那些天 1，2，3，4，等等
        // private String[] days;
        // // 作业运行的具体小时（选择天、周、月）
        // private int hour;
        // // 作业运行的具体分钟（选择天、周、月）
        // private int minutes;
        // // 作业运行起始时间（小时）
        // private int startHour;
        // // 选择小时时，运行间隔时间
        // private int hourInterval;
        // // 作业运行起始时间（分钟）
        // private int startMinutes;
        // // 选择分钟时，运行间隔时间
        // private int minutesInterval;
        // // 作业运行起始时间（小时）
        // private int endHour;
        // // 作业运行起始时间（分钟）
        // private int endMinutes;
        // 时区
        minutesInterval: undefined,
        hour: undefined,
        hourInterval: undefined,
        minutes: undefined,
        startHour: undefined,
        endHour: undefined,
        startMinutes: undefined,
        endMinutes: undefined,
      },
      result: {
        second: {},
        minute: {},
        hour: {},
        day: {},
        week: {},
        month: {},
        year: {},
      },
      endOpen: false,
      toString: "",
      isValue: "0",
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      labelColItem: { span: 8 },
      wrapperColItem: { span: 16 },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 20, offset: 4 },
        },
      },
      other: "",
      form: {
        name: "",
        region: undefined,
        date1: undefined,
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      rules: {
        scheduleCycle: [
          {
            required: true,
            message: "请选择调度周期",
            trigger: "change",
          },
        ],
        days: [{ required: false, message: "请选择时间", trigger: "change" }],
        minutesInterval: [
          {
            required: true,
            message: "请输入间隔时间",
            trigger: "change",
          },
        ],
        count: [
          {
            required: true,
            message: "请输入发生次数",
            trigger: "change",
          },
        ],
        tzid: [
          {
            required: true,
            message: "请选择时区",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    splitUnit() {
      const unit = this.dispatchList.filter(
        (item) => item.val === this.dispatchType
      )[0].title;
      return unit;
    },
  },
  model: {
    prop: "data",
    event: "change",
  },
  watch: {
    data: {
      handler(val) {
        // console.log(val, "sdasdasd");
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    moment,
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day');
    },

    disabledDateTime() {
      return {
        disabledHours: () => this.range(0, 24).splice(4, 20),
        disabledMinutes: () => this.range(30, 60),
        disabledSeconds: () => [55, 56],
      };
    },
    save(formData) {
      const self = this
      this.toString = "";
      this.$refs.ruleForm.validate((err, values) => {
        if (err) {
          var freq = this.dispatchList.find(
            (item) => this.data.scheduleCycle == item.val
          ).value;
          this.toString = `DTSTART;TZID=${this.data.tzid}:${moment(this.data.startValue).format('YYYYMMDDTHHMM00')} RRULE:INTERVAL=${this.data.minutesInterval};FREQ=${freq}`;
          if (this.isValue == "2") {
            this.toString = this.toString + ";UNTIL=" + moment(this.data.endValue).format('YYYYMMDDTHHMM00');
          } else if (this.isValue == "1") {
            this.toString = this.toString + ";COUNT=" + this.data.count;
          }
          switch (self.data.scheduleCycle) {
          case 0: //年
            break;
          case 3: //月
            self.toString =
              self.toString + ";BYMONTHDAY=" + self.data.days;
            break;
          case 2: //周
            self.toString =
              self.toString + ";BYDAY=" + self.data.weeks.join(",");
            break;
          case 1: //天
            break;
          case 4: //时
            break;
          case 5: //分
            break;
          }
          const params = {
            ...this.data,
            toString: this.toString,
          };
          this.$emit("saveRrule", formData, params);
        }
      });
    },
    disabledStartDate(startValue) {
      const endValue = this.data.endValue;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.data.startValue;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    handleStartOpenChange(open) {
      // if (!open) {
      //   this.endOpen = true;
      // }
    },
    handleEndOpenChange(open) {
      // this.endOpen = open;
    },
    changeDispatchType() {
      const params = {
        ...this.data,
        minutesInterval: undefined,
        hour: undefined,
        hourInterval: undefined,
        minutes: undefined,
        startHour: undefined,
        endHour: undefined,
        startMinutes: undefined,
        endMinutes: undefined,
        weeks: undefined,
        days: undefined,
        cron: "",
      };
      this.$emit("ok", params);
    },
    loadTimeList(type, index = 1) {
      let arr = [];
      if (type === 2) {
        arr = [
          { val: "SU", title: "星期日" },
          { val: "MO", title: "星期一" },
          { val: "TU", title: "星期二" },
          { val: "WE", title: "星期三" },
          { val: "TH", title: "星期四" },
          { val: "FR", title: "星期五" },
          { val: "SA", title: "星期六" },
        ];
        return arr;
      }
      let sum = type === 5 ? 60 : type === 4 ? 24 : type === 3 ? 32 : 60;
      for (let i = index; i < sum; i++) {
        var label = i;
        if (i < 10 && type !== 3) label = "0" + i;
        arr.push({
          title:
            type == 3
              ? i === 31
                ? "每月最后一天"
                : "每月" + Number(i) + "号"
              : label,
          val: type == 3 ? (i === 31 ? "L" : Number(i) + "") : i,
        });
      }
      return arr;
    },
  },
};
</script>
 
<style lang="less">
.card-container {
  background: #fff;
  text-align: left;
  overflow: hidden;
  // padding: 12px;
  position: relative;
  width: 100%;
  .p10-20 {
    margin-bottom: 10px;
  }
}
</style>