var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-top":"10px"}},[_c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('a-form',{staticClass:"p0-32-10-32 form-content",attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            { rules: [{ required: true, message: '调度名称不能为空!' }] },
          ]),expression:"[\n            'name',\n            { rules: [{ required: true, message: '调度名称不能为空!' }] },\n          ]"}],staticStyle:{"width":"96%"},attrs:{"placeholder":"请输入名称"}})],1),_c('a-form-item',{attrs:{"label":"目标"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'target_id',
            { rules: [{ required: true, message: '目标不能为空!' }] },
          ]),expression:"[\n            'target_id',\n            { rules: [{ required: true, message: '目标不能为空!' }] },\n          ]"}],staticStyle:{"width":"96%"},attrs:{"placeholder":"请选择目标"}},_vm._l((_vm.targetList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v(_vm._s(item.label))])}),1)],1),_c('a-form-item',{attrs:{"label":"是否启用"}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:(['enabled', { valuePropName: 'checked' }]),expression:"['enabled', { valuePropName: 'checked' }]"}]})],1),_c('a-form-item',{attrs:{"label":"类型"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'belong_type',
            { rules: [{ required: true, message: '调度类型不能为空!' }] },
          ]),expression:"[\n            'belong_type',\n            { rules: [{ required: true, message: '调度类型不能为空!' }] },\n          ]"}]},[_c('a-radio',{attrs:{"value":"task"}},[_vm._v("任务")]),_c('a-radio',{attrs:{"value":"embed_script"}},[_vm._v("脚本")]),_c('a-radio',{attrs:{"value":"embed_cmd"}},[_vm._v("命令")])],1)],1),(_vm.belongType === 'task')?_c('a-form-item',{attrs:{"label":"任务"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'belong_id',
            { rules: [{ required: true, message: '调度任务不能为空!' }] },
          ]),expression:"[\n            'belong_id',\n            { rules: [{ required: true, message: '调度任务不能为空!' }] },\n          ]"}],staticStyle:{"width":"96%"},attrs:{"placeholder":"请选择任务"}},_vm._l((_vm.region),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.task_id}},[_vm._v(_vm._s(item.name))])}),1)],1):_vm._e(),(_vm.belongType === 'embed_script')?_c('a-form-item',{attrs:{"label":"脚本"}},[_c('a-auto-complete',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'source',
            { rules: [{ required: true, message: '脚本不能为空!' }] },
          ]),expression:"[\n            'source',\n            { rules: [{ required: true, message: '脚本不能为空!' }] },\n          ]"}],attrs:{"data-source":_vm.scriptList,"placeholder":"输入或选择脚本"}})],1):_vm._e(),(_vm.belongType === 'embed_cmd')?_c('a-form-item',{attrs:{"label":"命令"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'code',
            { rules: [{ required: true, message: '命令内容不能为空!' }] },
          ]),expression:"[\n            'code',\n            { rules: [{ required: true, message: '命令内容不能为空!' }] },\n          ]"}],staticStyle:{"width":"96%"},attrs:{"type":"textarea","placeholder":"请输入命令内容"}})],1):_vm._e(),_c('a-form-item',{attrs:{"label":"描述"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'description',
            { rules: [{ required: false, message: '调度描述不能为空!' }] },
          ]),expression:"[\n            'description',\n            { rules: [{ required: false, message: '调度描述不能为空!' }] },\n          ]"}],staticStyle:{"width":"96%"},attrs:{"placeholder":"请输入描述"}})],1)],1),_c('Rrule',{ref:"RruleDispatch",attrs:{"data":_vm.rruleObj},on:{"saveRrule":_vm.saveDispatch}}),_c('div',{staticClass:"ant-modal-confirm-btns-new"},[_c('a-button',{staticStyle:{"margin-right":"10px"},attrs:{"type":"primary","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v("确认")]),_c('a-button',{on:{"click":function($event){$event.stopPropagation();return _vm.formCancel.apply(null, arguments)}}},[_vm._v("取消")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }